/* eslint-disable camelcase */
import { DialogButtonType } from '@/components/dialog/dialog';
import LoadingMask from '@/components/loadingMask/loadingMask';
import Pagination from '@/components/pagination';
import { PopupModel } from '@/components/popupModal/popupModal';
import SearchColumn from '@/components/searchArea/searchColumn';
import TableColumnComp from '@/components/tableColumnComp';
import TitleLabel from '@/components/titleLabel';
import { DATETIME_FORMAT } from '@/constants/datetime';
import useFetchApi from '@/hooks/useFetchApi';
import { IBaseRequestData } from '@/models/apiRequest';
import { DEFAULT_PAGE_COUNT } from '@/models/apiRequest/baseRequest';
import { ITableLayoutData, TableSearch, TableSearchEnum } from '@/models/layout/tableLayout';
import { ISystemAccount, SystemEditAccountMode, UserRoleEnum, UserRoleStatusEnum } from '@/models/page/systemSetting';
import { api } from '@/service/api';
import { accountManagement } from '@/service/modelTransfer/systemSetting/accountManagement';
import { subAccountManagement } from '@/service/modelTransfer/systemSetting/subAccountManagement';
import { alignCenterStyles, numericStyles, toFirstUpperCase } from '@/service/utils';
import { showDialog } from '@/store/slices/dialog.slice';
import { UseDisclosureProps } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { pluckFirst, useObservable, useObservableCallback, useSubscription } from 'observable-hooks';
import { createElement, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { withLatestFrom } from 'rxjs';
import { ISystemSettingLayoutProps, ISystemSettingPopupLayoutProps, SystemSettingLayoutEnum } from '../../layout.mapping';
import ResetPassword from '../../popupModelLayout/resetPassword';
import SubAccountList from '../../popupModelLayout/subAccountList';
import UpdateAccountStatus from '../../popupModelLayout/updateAccountStatus';
import EditAccount from './editAccount';
interface IRequest extends IBaseRequestData {
    Account: string;
    IsAccountFuzzySearch: boolean;
    IsUserNameFuzzySearch: boolean;
    RoleType: UserRoleEnum;
    Status: number;
    PageNo: number;
    PageSize: number;
}

const initParams: IRequest = {
    DateFrom: dayjs().format(DATETIME_FORMAT.YMDHMSS),
    DateTo: dayjs().format(DATETIME_FORMAT.YMDHMSS),
    PartnerId: 0,
    RegisterDate: { start: dayjs().format(DATETIME_FORMAT.YMDHMSS), end: dayjs().format(DATETIME_FORMAT.YMDHMSS) },
    RoleType: UserRoleEnum.None,
    Status: 0,
    Account: '',
    IsAccountFuzzySearch: false,
    UserName: '',
    IsUserNameFuzzySearch: false,
    PageNo: 1,
    PageSize: DEFAULT_PAGE_COUNT,
    UserId: 1,
};

const fakeData = {
    Data: [
        {
            ParentName: 'Parent sayaku',
            UserName: 'sayaku',
            Name: 'sayaku',
            Role: UserRoleEnum.Agent,
            Status: UserRoleStatusEnum.Enable,
            Agent_M6: 0,
            Agent_BB: 1,
            Agent_N8: 2,
            RegisterDate: '2021-11-11',
            UserId: 1,
        },
        {
            ParentName: 'Parent sayaku',
            UserName: 'sayaku2',
            Name: 'sayaku2',
            Role: UserRoleEnum.Agent,
            Status: UserRoleStatusEnum.Enable,
            Agent_M6: 1,
            Agent_BB: 1,
            Agent_N8: 2,
            RegisterDate: '2021-11-11',
            UserId: 2,
        },
        {
            ParentName: 'Parent sayaku',
            UserName: 'sayaku2',
            Name: 'sayaku2',
            Role: UserRoleEnum.Agent,
            Status: UserRoleStatusEnum.Disable,
            Agent_M6: 0,
            Agent_BB: 1,
            Agent_N8: 0,
            RegisterDate: '2021-11-11',
            UserId: 3,
        },
    ],
    Summary: { TotalRecords: 3 },
};

const AccountManagement = ({ type }: ISystemSettingLayoutProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState<IRequest>(initParams); // 紀錄搜尋列表的參數用
    const [requestData, setRequestData] = useState<IRequest>(searchData); // 紀錄 fetch api Request的值
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState<ITableLayoutData>();
    const [searchAreaHandler, searchChangeHandler$] = useObservableCallback<{ [key: string]: any }>((event$) => {
        return event$;
    });

    const search = useMemo<TableSearch[][]>(() => {
        return [
            [
                {
                    type: TableSearchEnum.INPUT,
                    matchKey: 'account',
                    label: t('Lbl_Account'),
                    placeholder: t('Txt_PleaseEnterAccount'),
                    hasFuzzy: true,
                    fuzzyKey: 'IsAccountFuzzySearch',
                },
                {
                    type: TableSearchEnum.INPUT,
                    matchKey: 'userName',
                    label: t('Txt_name'),
                    placeholder: t('Txt_PleaseEnterName'),
                    hasFuzzy: true,
                    fuzzyKey: 'IsUserNameFuzzySearch',
                },
            ],
            [
                {
                    type: TableSearchEnum.SELECT,
                    label: t('Th_Role'),
                    matchKey: 'roleType',
                    selectOptions: [
                        { id: UserRoleEnum.None, text: 'Txt_NoLimit' },
                        { id: UserRoleEnum.SubAgent, text: 'Lbl_Role_3' },
                        { id: UserRoleEnum.SeniorSubAgent, text: 'Lbl_Role_5' },
                        { id: UserRoleEnum.CommonManager, text: 'Lbl_Role_4' },
                        { id: UserRoleEnum.CommonManager2, text: 'Lbl_Role_6' },
                        { id: UserRoleEnum.CommonManager3, text: 'Lbl_Role_7' },
                        { id: UserRoleEnum.CommonManager4, text: 'Lbl_Role_8' },
                        { id: UserRoleEnum.CommonManager5, text: 'Lbl_Role_9' },
                        { id: UserRoleEnum.CommonManager6, text: 'Lbl_Role_10' },
                        { id: UserRoleEnum.CommonManager7, text: 'Lbl_Role_11' },
                    ],
                },
                {
                    type: TableSearchEnum.SELECT,
                    matchKey: 'status',
                    label: t('Th_status'),
                    placeholder: t('Lbl_All'),
                    selectOptions: [
                        { id: 0, text: t('Txt_NoLimit'), withoutI18n: true },
                        { id: 1, text: t('Lbl_enable'), withoutI18n: true },
                        { id: 2, text: t('Lbl_disable'), withoutI18n: true },
                    ],
                },
            ],
        ];
    }, []);

    const fetchHandler = useFetchApi<IRequest, { Data: ISystemAccount[]; TotalRecord: number }, any>(
        {
            path: '',
            method: 'POST',
        },
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, request, deps: [type] }) => {
            let _data: ITableLayoutData;
            if (value) {
                _data =
                    type === SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT
                        ? accountManagement(fakeData, request.PageNo, request.PageSize)
                        : subAccountManagement(value.data.Data, request.PageNo, request.PageSize);
                setResponseData(_data);
            }
            setIsLoading(false);
        },
        [type],
        (error) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        // init fetch
        fetchHandler({
            ...requestData,
            api: type === SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT ? { path: '/system/accountManagement', method: 'FAKE' } : api.subAccountList,
        });
    }, [type]);

    const [currentPopupContent, setPopupContent] = useState<{
        title: string;
        size?: string;
        isOpen: boolean;
        content: (props: ISystemSettingPopupLayoutProps) => JSX.Element;
        props?: any;
    }>({
        title: '',
        isOpen: false,
        content: () => <></>,
    });

    const params = useMemo<UseDisclosureProps>(() => {
        return {
            title: currentPopupContent.title,
            isOpen: currentPopupContent.isOpen,
            size: currentPopupContent.size,
            onClose: () => {
                setPopupContent((s) => ({ ...s, isOpen: false }));
            },
        };
    }, [currentPopupContent]);

    const configSearch$ = useObservable(pluckFirst, [search]);
    const searchData$ = useObservable(pluckFirst, [searchData]);
    const type$ = useObservable(pluckFirst, [type]);

    // 按下搜尋鈕後會觸發
    useSubscription(searchChangeHandler$.pipe(withLatestFrom(configSearch$, searchData$, type$)), ([searchRef, configSearchData, _searchData, _type]) => {
        if (responseData && responseData?.totalRecords) {
            responseData.totalRecords = 0;
        }

        const resultData = { ...initParams, ..._searchData, PageNo: 1 }; // 每次搜尋後頁數要reset
        const setData = (searchItem: TableSearch, _data: IRequest) => {
            const _searchItem = searchItem;
            const transferList = ['roleType', 'status'];
            if (_searchItem.matchKey) {
                (_data as any)[toFirstUpperCase(_searchItem.matchKey)] = transferList.includes(_searchItem.matchKey)
                    ? ~~searchRef[_searchItem.matchKey]
                    : searchRef[_searchItem.matchKey];
                const fuzzySearchKey = !!_searchItem.fuzzyKey ? _searchItem.fuzzyKey : 'IsFuzzySearch';
                _searchItem.hasFuzzy && ((_data as any)[fuzzySearchKey] = !!searchRef[fuzzySearchKey]);
            } else if (_searchItem.dateMatchKey) {
                const startName = _searchItem.dateMatchKey!['startDate'] || 'startDate';
                const endName = _searchItem.dateMatchKey!['endDate'] || 'endDate';
                (_data as any)[startName] = searchRef[startName];
                (_data as any)[endName] = searchRef[endName];
            }
        };

        configSearchData?.forEach((searchItem) => {
            if (Array.isArray(searchItem)) {
                const _searchItemList = searchItem as TableSearch[];
                _searchItemList.forEach((_searchItem) => {
                    setData(_searchItem, resultData);
                });
                return;
            }
            setData(searchItem, resultData);
        });

        setSearchData({ ...resultData });
        setRequestData(resultData);
        fetchHandler({
            ...resultData,
            api: _type === SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT ? { path: '/system/accountManagement', method: 'FAKE' } : api.subAccountList,
        });
    });

    const popupContent = useMemo(() => {
        return createElement(currentPopupContent.content, {
            ...(currentPopupContent.props || {}),
            onClose: () => {
                setPopupContent((s) => ({ ...s, isOpen: false }));
            },
        });
    }, [currentPopupContent]);

    const [editMode, setEditMode] = useState(SystemEditAccountMode.NONE);
    const getTabLabel = useCallback(
        (editMode: SystemEditAccountMode) => {
            switch (editMode) {
                case SystemEditAccountMode.CREATE:
                    return t(type === SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT ? 'Lbl_AddAccount' : 'Lbl_AddSubAccount');
                case SystemEditAccountMode.UPDATE:
                    return t(type === SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT ? 'Lbl_EditAccount' : 'Lbl_EditSubAccount');
                default:
                    return '';
            }
        },
        [type],
    );

    const [currentUserId, setUserId] = useState<number | undefined>();

    const goToMainPage = useCallback(() => {
        setEditMode(SystemEditAccountMode.NONE);
        fetchHandler({
            ...requestData,
            api: type === SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT ? { path: '/system/accountManagement', method: 'FAKE' } : api.subAccountList,
        });
    }, [requestData, type]);

    return (
        <>
            <div className="flex justify-between my-4">
                <TitleLabel>
                    <>
                        <span
                            data-active={editMode !== SystemEditAccountMode.NONE}
                            className="data-active:cursor-pointer data-active:hover:text-orange-300"
                            onClick={() => {
                                if (editMode !== SystemEditAccountMode.NONE) {
                                    goToMainPage();
                                }
                            }}>
                            {t(type === SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT ? 'Nav_AccountManagement' : 'Nav_SubAccountManagement')}
                        </span>
                        {editMode !== SystemEditAccountMode.NONE && <span> / {getTabLabel(editMode)}</span>}
                    </>
                </TitleLabel>
                {editMode === SystemEditAccountMode.NONE && (
                    <button
                        className="self-end p-2 mr-4 bg-affiliate text-white rounded hover:bg-gray-500"
                        onClick={() => {
                            setEditMode(SystemEditAccountMode.CREATE);
                        }}>
                        <span className="px-1 icon icon-add-outline" />
                        <label className="px-1 cursor-pointer">{t('Lbl_AddSubAccount')}</label>
                    </button>
                )}
            </div>
            {editMode === SystemEditAccountMode.NONE && (
                <div data-list-page>
                    <div data-index-page>
                        <div data-search-area>
                            <SearchColumn
                                defaultValue={requestData}
                                search={search}
                                handler={(data) => {
                                    searchAreaHandler(data);
                                }}
                                layout="justify-start"
                            />
                        </div>
                    </div>

                    <div data-table-container className="m-2 ">
                        <div className="table w-full min-w-[300px]">
                            <div className="table-header-group bg-gray-100 text-xs">
                                <div className="table-row ">
                                    {(responseData?.tableHeader ?? [])
                                        .filter((item) => !item.invisible)
                                        .map((item, i) => {
                                            return (
                                                <div
                                                    key={`table-header-${i}`}
                                                    className={`table-cell p-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                        alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                    }`}>
                                                    {t(item.text)}
                                                </div>
                                            );
                                        })}
                                    <div className="table-cell p-6">{t('Lbl_EditSubAccount')}</div>
                                    <div className="table-cell p-6">{t('Lbl_resetPassword')}</div>
                                </div>
                            </div>

                            <div className="table-row-group">
                                {(responseData?.tableBody ?? []).map((hash, pi) => {
                                    return (
                                        <Fragment key={`ff-${pi}`}>
                                            <div className="table-row odd:bg-gray-200 even:bg-slate-50" key={`table-td-tr-${pi}`}>
                                                {(responseData?.tableHeader ?? [])
                                                    .filter((item) => !item.invisible)
                                                    .map((item, i) => {
                                                        const isStatus = item.name === 'status';

                                                        return (
                                                            <div
                                                                className={`table-cell py-4 px-6 ${numericStyles.includes(item.type) ? 'text-right' : ''} ${
                                                                    alignCenterStyles.includes(item.type) ? 'text-center' : ''
                                                                }`}
                                                                key={`tbody-${item.name}`}>
                                                                <div
                                                                    className={`${
                                                                        isStatus ? 'text-blue-500 hover:underline hover:text-blue-600 cursor-pointer' : ''
                                                                    }`}
                                                                    onClick={() => {
                                                                        if (isStatus) {
                                                                            setPopupContent({
                                                                                title: '修改帳號狀態',
                                                                                isOpen: true,
                                                                                content: UpdateAccountStatus,
                                                                                props: {
                                                                                    status: hash[item.name],
                                                                                    updateStatusFn: (isSuccess: boolean) => {
                                                                                        dispatch(
                                                                                            showDialog({
                                                                                                title: t('Lbl_Button_Info'),
                                                                                                content: t(
                                                                                                    isSuccess ? 'Txt_updateSuccess' : 'Txt_updateFailed',
                                                                                                ),
                                                                                                onClose: () => {
                                                                                                    goToMainPage();
                                                                                                },
                                                                                                type: isSuccess
                                                                                                    ? DialogButtonType.Info
                                                                                                    : DialogButtonType.Error,
                                                                                            }),
                                                                                        );
                                                                                    },
                                                                                    loadingVisibleFn: (visible: boolean) => {
                                                                                        setIsLoading(visible);
                                                                                    },
                                                                                    account: hash['account'],
                                                                                },
                                                                            });
                                                                        }
                                                                    }}>
                                                                    <TableColumnComp text={hash[item.name]} style={item.type} />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                <div className="table-cell py-4 px-6">
                                                    <div className="flex">
                                                        <span
                                                            className="icon icon-edit-profile bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400 m-1"
                                                            onClick={() => {
                                                                setUserId(+hash['userId']);
                                                                setEditMode(SystemEditAccountMode.UPDATE);
                                                            }}
                                                        />
                                                        {type === SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT && (
                                                            <span
                                                                className="icon icon-submember bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400 m-1"
                                                                onClick={() => {
                                                                    setPopupContent({
                                                                        title: '子帳號列表',
                                                                        isOpen: true,
                                                                        size: '4xl',
                                                                        content: SubAccountList,
                                                                        props: {
                                                                            userName: hash['userName'],
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="table-cell py-4 px-6">
                                                    <span
                                                        className="icon icon-key bg-gray-500 text-gray-100 border-2 border-gray-400 rounded p-1 cursor-pointer hover:bg-gray-400 m-1"
                                                        onClick={() => {
                                                            setPopupContent({
                                                                title: '提示',
                                                                isOpen: true,
                                                                content: ResetPassword,
                                                                props: {
                                                                    updateStatusFn: (isSuccess: boolean) => {
                                                                        dispatch(
                                                                            showDialog({
                                                                                title: t('Lbl_Button_Info'),
                                                                                content: t(isSuccess ? 'Txt_updatePasswordSuccess' : 'Txt_updateFailed'),
                                                                                type: isSuccess ? DialogButtonType.Info : DialogButtonType.Error,
                                                                            }),
                                                                        );
                                                                    },
                                                                    loadingVisibleFn: (visible: boolean) => {
                                                                        setIsLoading(visible);
                                                                    },
                                                                    account: hash['account'],
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div data-pagination className="mt-2 flex justify-center">
                        <span className=" text-gray-500 self-center mr-2">{t('Lbl_TotalRecord').replace('{0}', `${responseData?.totalRecords || 0}`)}</span>
                        <Pagination
                            key={`member-admain-pagination-${responseData?.totalRecords || 1}`}
                            pageCount={Math.ceil((responseData?.totalRecords || 1) / DEFAULT_PAGE_COUNT)}
                            onPageChange={(e) => {
                                setSearchData((data) => {
                                    if (data) {
                                        const result = { ...data, PageNo: e.selected + 1 };
                                        fetchHandler({
                                            ...result,
                                            api:
                                                type === SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT
                                                    ? { path: '/system/accountManagement', method: 'FAKE' }
                                                    : api.subAccountList,
                                        });
                                        return result;
                                    }

                                    return data;
                                });
                            }}
                        />
                    </div>
                </div>
            )}

            {editMode !== SystemEditAccountMode.NONE && (
                <div data-edit-page>
                    <EditAccount mode={editMode} type={type} userId={currentUserId} onClose={goToMainPage} />
                </div>
            )}

            <PopupModel {...params}>{popupContent}</PopupModel>
            <LoadingMask visible={isLoading} />
        </>
    );
};

export default AccountManagement;

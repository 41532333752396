import { ITableLayoutData, TableColumnStyle, TableConfig } from '@/models/layout/tableLayout';
import { IReportTeamData } from '@/models/page/report/IReportTeam';
import { getTableBody, getTableFooter, getTableHeader } from '@/service/tableHelper';

export function teamData(input: IReportTeamData[]): ITableLayoutData {
    const outputConfig: TableConfig<IReportTeamData> = {
        column: [
            { name: 'partnerId', text: 'Th_partnerId', itemKeys: ['PartnerId'], type: TableColumnStyle.Partner },
            { name: 'level', text: 'Th_level', itemKeys: ['Level'], type: TableColumnStyle.Numeric },
            { name: 'userName', text: 'Th_proxyName', itemKeys: ['UserName'], type: TableColumnStyle.Text },
            { name: 'agentNickName', text: 'Th_agentNickName', itemKeys: ['AgentNickName'], type: TableColumnStyle.Text },
            { name: 'proxyName', text: 'Th_UplineAgent', itemKeys: ['ProxyName'], type: TableColumnStyle.Text },
            { name: 'registerNum', text: 'Th_registerNum', itemKeys: ['RegisterNum'], type: TableColumnStyle.Numeric },
            { name: 'firstRechargeNum', text: 'Th_firstRechargeNum', itemKeys: ['FirstRechargeNum'], type: TableColumnStyle.Numeric },
            { name: 'firstRechargeSum', text: 'Th_firstChargeAmount', itemKeys: ['FirstRechargeSum'], type: TableColumnStyle.CommaNumeric },
            { name: 'rechargeSum', text: 'Th_rechargeSum', itemKeys: ['RechargeSum'], type: TableColumnStyle.CommaNumeric },
            { name: 'effectAmountSum', text: 'Th_betAmount', itemKeys: ['EffectAmountSum'], type: TableColumnStyle.CommaNumeric },
            { name: 'totalWinSum', text: 'Th_totalWin', itemKeys: ['TotalWinSum'], type: TableColumnStyle.CommaNumericWithSymbol },
            { name: 'upAmountSum', text: 'Th_effectAmountSum', itemKeys: ['UpAmountSum'], type: TableColumnStyle.CommaNumeric },
        ],
        footer: [
            'registerNum',
            'firstRechargeNum',
            'firstRechargeSum',
            'rechargeSum',
            'effectAmountSum',
            'totalWinSum',
            'upAmountSum',
        ],
    };

    const tableHeader = getTableHeader(outputConfig.column);

    const tableBody = getTableBody(input, outputConfig.column);
    const tableFooter = getTableFooter(outputConfig, input, tableBody);

    return { tableHeader, tableBody, tableFooter, totalRecords: input.length, hasFooter: outputConfig.footer && outputConfig.footer.length > 0 };
}

import { ReportPageEnum } from '../transfer/reportTransfer';

export const tableNoteMapping: Record<ReportPageEnum, string[]> = {
    [ReportPageEnum.FUND]: [
        '红色正数代表公司盈利，绿色负数代表公司成本；',
        '为了保障数据安全及即时性，报表只保留3个自然月内数据；',
        '下注会员数：时间段内有投注行为的会员数；',
        '投注金额：会员下注金额并且已结算注单金额，不包含取消无效注单；',
    ],
    [ReportPageEnum.FUND_NEW_ACCOUNT]: [],
    [ReportPageEnum.COMMISSION]: [
        '品牌佣金差异，会依照各品牌结算时间来比对，比对时间如下',
        '米乐:每月6-10日(品牌每月1日-5日进行上月的总佣金结算)',
        'BB:每月6-10日(品牌每月1日-5日进行上月的总佣金结算)',
        '牛8:每月6-10日(品牌每月1日-5日进行上月的总佣金结算)',
        '${icon-notification}:表示有佣金差异存在，计算方式为品牌方-内部数据',
    ],
    [ReportPageEnum.COMMISSION_AGENT_LEVEL]: ['实际发放=下级代理佣金总额×提成比例', '佣金百分比，当下级出现负佣金，佣金百分比为0.0%'],
    [ReportPageEnum.COMMISSION_MEMBER]: [
        '输赢，公司盈利为红色正数，公司亏损为绿色负数；',
        '实际发放:\n(1)净盈利 = 输赢 - 平台费 - 红利 - 存提手续费；\n(2)成本总计 = 红利 + 存提款手续费 + 平台费(公司亏损则平台费为0)；\n(3)累加上月：当上月总输赢 - 上月总成本 <0，才会累计到下月结算;',
        '当净盈利≤0 (公司亏损) 或有效会员数未满足要求时，佣金比例显示为100%；',
        '因品牌数据抓取限制，目前系统只提供比对以下栏位差异: 输赢，红利，返水，存提手续费，净营利，实际发放',
        '差异: 计算方式为品牌数据 - 内部数据',
    ],
    [ReportPageEnum.COMMISSSION_MEMBER_DETAIL]: [],
    [ReportPageEnum.COMMISSION_RECHARGE_WITHDRAW_FEE]: [],
    [ReportPageEnum.TEAM]: [
        '红色正数代表公司盈利，绿色负数代表公司成本；',
        '为了保障数据安全及即时性，只保留3个自然月内数据；',
        '充值金额：只统计会员自己充值的金额；',
        '投注金额：会员下注金额并且已结算，不包含取消无效注单；',
        '代充金额：只统计会员通过代理代充渠道的充值金额；',
    ],
    [ReportPageEnum.MEMBER]: [
        '红色正数代表公司盈利，绿色负数代表公司成本；',
        '为了保障数据安全及即时性，只保留3个自然月内数据；',
        '投注金额：会员下注金额并且已结算，不包含取消无效注单；',
        '未下注天数：统计会员当前时间距离最后一次下注时间的自然天数；',
    ],
    [ReportPageEnum.GAME]: [
        '为了保障数据安全及即时性，只保留3个自然月内数据；',
        '下注会员数：时间段内有投注行为的会员数；',
        '下注金额：会员真实下注包含已取消、和局、无效、有效订单金额；',
        '投注金额：会员下注金额并且已结算，不包含取消无效注单；',
        '有效投注：相应类型游戏投注，任何对押、和局、无效的、投注失败的、未产生输赢结果的投注不计算到投注金额中，体育类港赔0.75 / 欧赔1.75以下的投注不计算为有效投注；',
    ],
    [ReportPageEnum.GAME_BRAND]: [
        '为了保障数据安全及即时性，只保留3个自然月内数据；',
        '下注会员数：时间段内有投注行为的会员数；',
        '下注金额：会员真实下注包含已取消、和局、无效、有效订单金额；',
        '投注金额：会员下注金额并且已结算，不包含取消无效注单；',
        '有效投注：相应类型游戏投注，任何对押、和局、无效的、投注失败的、未产生输赢结果的投注不计算到投注金额中，体育类港赔0.75 / 欧赔1.75以下的投注不计算为有效投注；',
    ],
    [ReportPageEnum.FIRST_CHARGE]: ['红色正数代表公司盈利，绿色负数代表公司成本；', '为了保障数据安全及即时性，只保留3个自然月内数据；'],
    [ReportPageEnum.REBATE]: ['为了保障数据安全及即时性，只保留3个自然月内数据；'],
    [ReportPageEnum.REBATE_BONUS_DETAIL]: ['为了保障数据安全及即时性，只保留3个自然月内数据；'],
    [ReportPageEnum.REBATE_USER_DETAIL]: ['为了保障数据安全及即时性，只保留3个自然月内数据；'],
    [ReportPageEnum.CHARGE_AND_REDRAW]: ['为了保障数据安全及即时性，只保留3个自然月内数据；'],
    [ReportPageEnum.WITHDRAW_DEPOSIT_DETAIL]: ['为了保障数据安全及即时性，只保留3个自然月内数据；'],
    [ReportPageEnum.RECHARGE_DEPOSIT_DETAIL]: ['为了保障数据安全及即时性，只保留3个自然月内数据；'],
    [ReportPageEnum.AGENT_RECHARGE]: ['为了保障数据安全及即时性，只保留3个自然月内数据；'],
    [ReportPageEnum.RELATION_MEMBER]: [
        '每月 5 号最后结算上月的关联会员报表, 请在执行前确认所有关联资料',
        '红色正数代表公司盈利，绿色负数代表公司成本；',
        '投注金额：会员下注金额并且已结算，不包含取消无效注单；',
        '投注率: 投注金额/储值金额(充值+代充), 客户活跃度或愿意参与投注率的指标。计算后将分成以下 5 个等级',
    ],
    [ReportPageEnum.RELATION_MEMBER_DETAIL]: [
        '红色正数代表公司盈利，绿色负数代表公司成本；',
        '投注金额：会员下注金额并且已结算，不包含取消无效注单；',
        '未下注天数: 统计会员当前时间距离最后一次下注时间的自然天数;',
        '投注率: 投注金额/储值金额(充值+代充), 客户活跃度或愿意参与投注率的指标。计算后将分成以下 5 个等级',
    ],
};
